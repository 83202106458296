import React, { useState } from 'react'
import styled from 'styled-components'
import Block from '../../../../../../layouts/Blocks'
import Link from '../../../../../../components/Link'
import { actionPOST } from '../../../../../../modules/fetch-data'
//import 'react-confirm-alert/src/react-confirm-alert.css'                 <- ###  Check if still need - because it breaks the storybook
import { htmlParser } from '../../../../../../modules/parse'
import translate from '../../../../../../modules/translate'
import Text from '../../../../../../components/Text'
import Overlay from '../../../../../../components/Overlay'
import userSession from '../../../../../Profile/modules/user-session'
import TitleTopic from '../../../../../../components/TitleTopic'

const Wrapper = styled.div`
  margin-bottom: 40px;
`

const StyledTitleTopic = styled(TitleTopic)`
  margin-bottom: 20px;
`

const StyledLink = styled(Link)`
  cursor: pointer;
`

const TextDiv = styled.div`
  width: 90%;

  margin-left: auto;
  margin-right: auto;
  text-align: right;
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    width: unset;
    margin-bottom: 15px;
  }
`
const FlexDiv = styled.div`
  display: flex;
  width: 90%;

  margin-left: auto;
  margin-right: auto;

  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-bottom: 6px;
    width: unset;
  }
`
const CrossDiv = styled.div`
  width: 18px;
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-top: -13px;
  }
  margin-left: auto;
  :after {
    background-color: #999;

    content: '';
    display: block;
    height: 2px;
    margin: 7px 0;

    transform: translateY(14px) rotate(135deg);
  }

  :before {
    background-color: #999;

    content: '';
    display: block;
    height: 2px;
    margin: 7px 0;

    transform: translateY(23px) rotate(-135deg);
  }
  cursor: pointer;
`

const SizeDiv = styled.div`
  width: 95%;
`

const OverlayTitle = styled.div`
  padding: 0.7rem 2rem;
  background-color: #d41e25;
  color: white;
  font-family: ${(props) => props.theme.font.primary};
`
const StyledOverlay = styled(Overlay)`
  text-transform: uppercase;
`

const OverlayButtonDiv = styled.div`
  display: flex;
`
const OverlayButton = styled.div`
  padding: 0.7rem;
  background-color: white;
  font-family: ${(props) => props.theme.font.primary};
  width: 50%;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: #eeeeee;
  }
`

const StyledText = styled(Text)`
  margin: 0 auto;
  margin-top: ${(props) => props.theme.sizes.gapVertical};
  margin-bottom: 30vh;
  font-size: ${(props) => props.theme.fontSize.title2};
  margin-left: ${(props) => props.theme.sizes.gapEdge};
  margin-right: ${(props) => props.theme.sizes.gapEdge};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.title2};
    margin-left: ${(props) => props.theme.tablet.sizes.gapEdge};
    margin-right: ${(props) => props.theme.tablet.sizes.gapEdge};
    margin-top: ${(props) => props.theme.tablet.sizes.gapVertical};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-left: ${(props) => props.theme.mobile.sizes.gapEdge};
    margin-right: ${(props) => props.theme.mobile.sizes.gapEdge};
    font-size: ${(props) => props.theme.mobile.fontSize.title2};
    margin-top: ${(props) => props.theme.mobile.sizes.gapVertical};
  }

  text-align: center;
`

const StyledBlock = styled(Block)`
  padding: unset;
`
const BookmarkList = ({ data, showDate, run, ...props }) => {
  const [stateData, setStateData] = useState({ data: data, showOverlay: false })

  function deleteOne(articleData) {
    actionPOST([], {
      location: '/user/bookmarks/delete',
      access: userSession.getAccessToken(),
      data: articleData,
    })

    let articlearray = []
    stateData.data.forEach(function (i) {
      if (i.url.localeCompare(articleData.article) !== 0) {
        articlearray.push(i)
      }
    })
    setStateData({ data: articlearray, showOverlay: false })
  }

  function deleteAll() {
    actionPOST([], {
      location: '/user/bookmarks/delete-all',
      access: userSession.getAccessToken(),
    })
    setStateData({ data: [], showOverlay: false })
  }

  function deleteButton() {
    setStateData({ data: stateData.data, showOverlay: true })
  }

  function closeOverlay() {
    setStateData({ data: stateData.data, showOverlay: false })
  }

  return (
    <Wrapper {...props}>
      <StyledTitleTopic view="screen" title={data && data.title ? data.title : 'Lesezeichen'} />
      {stateData && stateData.showOverlay && (
        <StyledOverlay onClose={() => closeOverlay()}>
          <OverlayTitle>{htmlParser(translate('bookmark_question'))}</OverlayTitle>
          <OverlayButtonDiv>
            <OverlayButton onClick={() => deleteAll()}>{htmlParser(translate('bookmark_yes'))}</OverlayButton>
            <OverlayButton onClick={() => closeOverlay()}>{htmlParser(translate('bookmark_no'))}</OverlayButton>
          </OverlayButtonDiv>
        </StyledOverlay>
      )}
      {stateData && stateData.data.length == 0 && <StyledText>{htmlParser(translate('no_bookmark_entry'))}</StyledText>}
      {stateData && stateData.data.length > 0 && (
        <div>
          <TextDiv>
            <StyledLink onClick={() => deleteButton()}>{htmlParser(translate('delete_all_bookmarks'))}</StyledLink>
          </TextDiv>
          {stateData &&
            stateData.data.map((entry) => {
              return (
                <FlexDiv>
                  <SizeDiv>
                    <StyledBlock view="landscape_article" data={{ articles: [entry], hideDefaults: !showDate }} />
                  </SizeDiv>
                  <CrossDiv onClick={() => deleteOne({ article: entry.url })}></CrossDiv>
                </FlexDiv>
              )
            })}
        </div>
      )}
    </Wrapper>
  )
}
BookmarkList.propTypes = {}
BookmarkList.defaultProps = {}
export default BookmarkList
