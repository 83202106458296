import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 9001;
`

const OverlayContent = styled.div`
  min-width: 120px;
  min-height: 70px;
  max-width: 100%;
  max-height: 100%;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
`
const Overlay = ({ ...props }) => {
  function close(e) {
    if (typeof props.onClose == 'function' && e && e.target && e.target.offsetParent == document.body) {
      props.onClose()
    } else {
      //TODO
    }
  }

  return (
    <Wrapper onClick={close}>
      <OverlayContent>{props.children}</OverlayContent>
    </Wrapper>
  )
}
Overlay.propTypes = {}
Overlay.defaultProps = {}
export default Overlay
