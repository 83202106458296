import React from 'react'
import styled from 'styled-components'
import BookmarkList from './components/BookmarkList'
import TitleTopic from '../../../../components/TitleTopic'

const Wrapper = styled.div``

const BookmarksResult = ({ data, run, meta, ...props }) => (
  <Wrapper {...props}>
    {data && data.articles && <BookmarkList data={data.articles} run={run} showDate={true} />}
  </Wrapper>
)

BookmarksResult.propTypes = {}
BookmarksResult.defaultProps = {}
export default BookmarksResult
